import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import { get } from "lodash";

import { getDataByPageType } from "../components/SplashDetail/lib/helpers";
import Layout from "../components/SplashDetail/components/Layout";
import Header from "../components/SplashDetail/components/Header";
import SplashDetail from "../components/SplashDetail";
import links from "../components/SplashDetail/components/Header/lib/links.json";

const Splash = ({ data }) => {
    const { splashPage } = data;
    const {
        headBeginScript,
        headEndScript,
        bodyBeginScript,
        bodyEndScript,
        headerTitle,
        headerSubTitle,
        headerDarkText,
        headerImage,
        headerImageCustom,
        headerImageAlignment,
        contentTitle,
        contentImage,
        displayApplyNow,
        modules,
        blogWord,
        templateKey,
        vanityUrl,
        slug,
        title,
        jobTitle,
        firestoreId,
    } = splashPage;

    const {
        applyNow,
        applyNowScript,
        logIn,
        profile,
        gallery,
        contact,
        team,
        blogs,
        news,
        managers,
        loanOfficers,
        teams,
        staff,
        branch,
        slug: ownerSlug,
    } = getDataByPageType(splashPage);

    let relatedProps = {
        pageTitle: title,
        vanityUrl,
        contentTitle,
        contentImage,
        displayApplyNow,
        slug,
        applyNow,
        applyNowScript,
        logIn,
        licenseIds: profile?.licenseIds || [],
        branch,
        blogs,
        corporateBlogs: news,
        gallery,
        team,
        managers,
        loanOfficers,
        teams,
        staff,
        profile,
        modules,
        blogWord,
        templateKey,
        headerImage,
        headerImageCustom,
        headerImageAlignment,
        headerTitle,
        headerSubTitle,
        headerDarkText,
        headBeginScript,
        headEndScript,
        bodyBeginScript,
        bodyEndScript,
        contact,
        jobTitle,
        ownerSlug,
        firestoreId,
    };

    // get dba logo from branch
    const dbaLogoBlack = get(branch, "dbaLogoBlack", null);

    const header = (
        <Header
            active={[]}
            theme="light"
            dbaBlack={dbaLogoBlack}
            clearDba={true}
            displayApplyNow={displayApplyNow}
            applyNow={applyNow}
            applyNowScript={applyNowScript}
            links={links}
            ownerSlug={ownerSlug}
        />
    );

    // if branch - add email, phone, mobilePhone, fax
    if (templateKey === "branch") {
        relatedProps.email = branch.email;
        relatedProps.phone = branch.phone;
        relatedProps.mobilePhone = branch.mobilePhone;
        relatedProps.fax = branch.fax;
    }

    return (
        <Layout
            hasHero={true}
            header={header}
            headBeginScript={headBeginScript}
            headEndScript={headEndScript}
            bodyBeginScript={bodyBeginScript}
            bodyEndScript={bodyEndScript}>
            <Helmet>
                <title>{`${title} | Bay Equity Home Loans`}</title>
                <meta name="description" content={`content TBD`} />
                <meta http-equiv="X-UA-Compatible" content="IE=11" />
                <script
                    src={`https://embed.signalintent.com/js/embedded.js?org-guid=${process.env.GATSBY_SIGNAL_INTENT_ORG_GUID}`}></script>
            </Helmet>
            <SplashDetail
                {...profile}
                {...contact}
                {...relatedProps}
                isPreview={false}
            />
        </Layout>
    );
};

export default Splash;

export const pageQuery = graphql`
    query getSplashPageById($id: String!) {
        splashPage(id: { eq: $id }) {
            id
            firestoreId
            slug
            headBeginScript {
                name
                src
                async
                html
            }
            headEndScript {
                name
                src
                async
                html
            }
            bodyBeginScript {
                name
                src
                async
                html
            }
            bodyEndScript {
                name
                src
                async
                html
            }
            title
            vanityUrl
            displayApplyNow
            headerImage
            headerImageCustom
            headerImageAlignment
            headerDarkText
            headerTitle
            headerSubTitle
            blogWord
            templateKey
            loanOfficer {
                slug
                hidden
                applyNow
                applyNowScript
                gallery {
                    photo
                }
                profile {
                    name
                    email
                    jobTitle
                    licenseIds {
                        license
                        id
                    }
                    licenseLink
                    branch {
                        slug
                        title
                        address1
                        address2
                        address3
                        city
                        state
                        zip
                        lat
                        lon
                        dbaLogoBlack
                    }
                }
                contact {
                    phone
                    mobilePhone
                    fax
                    facebook
                    instagram
                    linkedin
                    twitter
                    youtube
                }
                team {
                    slug
                    title
                }
                blogs {
                    id
                    html
                    slug
                    title
                    author {
                        slug
                        profile {
                            name
                        }
                        gallery {
                            photo
                        }
                    }
                    date
                    feature
                    categories
                    image
                }
                news {
                    id
                    html
                    slug
                    title
                    date
                    feature
                    categories
                    image
                }
            }
            team {
                slug
                applyNow
                applyNowScript
                profile {
                    name
                    email
                    logo
                    licenseIds {
                        license
                        id
                    }
                    branch {
                        slug
                        title
                        address1
                        address2
                        address3
                        city
                        state
                        zip
                        lat
                        lon
                        dbaLogoBlack
                    }
                }
                contact {
                    phone
                    mobilePhone
                    fax
                    facebook
                    instagram
                    linkedin
                    twitter
                    youtube
                }
                loanOfficers {
                    slug
                    gallery {
                        photo
                    }
                    profile {
                        name
                        email
                        jobTitle
                        licenseIds {
                            license
                            id
                        }
                    }
                }
                managers {
                    photo
                    name
                    title
                    license
                    email
                }
                staff {
                    photo
                    name
                    title
                    license
                    email
                }
                blogs {
                    id
                    html
                    slug
                    title
                    author {
                        slug
                        profile {
                            name
                        }
                        gallery {
                            photo
                        }
                    }
                    date
                    feature
                    categories
                    image
                }
                news {
                    id
                    html
                    slug
                    title
                    date
                    feature
                    categories
                    image
                }
            }
            nonOriginator {
                gallery {
                    photo
                }
                profile {
                    name
                    email
                    jobTitle
                    licenseIds {
                        license
                        id
                    }
                    branch {
                        slug
                        title
                        address1
                        address2
                        address3
                        city
                        state
                        zip
                        lat
                        lon
                        dbaLogoBlack
                    }
                }
                contact {
                    phone
                    mobilePhone
                    fax
                    facebook
                    instagram
                    linkedin
                    twitter
                    youtube
                }
                news {
                    id
                    html
                    slug
                    title
                    date
                    feature
                    categories
                    image
                }
            }
            branch {
                slug
                title
                owner
                dbaLogoBlack
                profile {
                    name
                    email
                    licenseIds {
                        license
                        id
                    }
                }
                email
                phone
                mobilePhone
                address1
                address2
                address3
                city
                state
                zip
                lat
                lon
                contact {
                    phone
                    mobilePhone
                    fax
                    facebook
                    instagram
                    linkedin
                    twitter
                    youtube
                }
                loanOfficers {
                    slug
                    hidden
                    gallery {
                        photo
                    }
                    profile {
                        name
                        email
                        jobTitle
                        licenseIds {
                            license
                            id
                        }
                    }
                }
                teams {
                    slug
                    hidden
                    profile {
                        name
                        email
                        logo
                        licenseIds {
                            license
                            id
                        }
                    }
                }
                staff {
                    photo
                    name
                    title
                    license
                    email
                }
                managers {
                    photo
                    name
                    title
                    license
                    email
                }
            }
            modules {
                type
                display
                videos {
                    videoTitle
                    videoCaption
                    videoUrl
                }
                facebookPageUrl
                bookings
                calendar
                useBackgroundImage
                backgroundImage
                backgroundImageCustom
                darkTitleText
                instagramToken
                resourceIntro
                resourceList {
                    resourceFile
                    resourceUrl
                    resourceDescription
                }
                event1 {
                    eventImage
                    eventCustomImage
                    eventName
                    eventDate
                    eventTime
                    eventLocation
                    eventDescription
                    eventUrl
                    eventCta
                }
                event2 {
                    eventImage
                    eventCustomImage
                    eventName
                    eventDate
                    eventTime
                    eventLocation
                    eventDescription
                    eventUrl
                    eventCta
                }
                event3 {
                    eventImage
                    eventCustomImage
                    eventName
                    eventDate
                    eventTime
                    eventLocation
                    eventDescription
                    eventUrl
                    eventCta
                }
                awards {
                    awardDescription
                    awardYear
                    awardImage
                    awardBadge
                }
                social_id
                social_page
                showCalculatorMortgage
                showCalculatorRentVsBuy
                showCalculatorHomeAffordability
                showCalculatorRequiredIncome
                showCalculatorRefinance
                contentImage
                contentTitle
                contentText
                profileShowCompactView
                profileTitle
                profileBody
                contactFormFields {
                    name
                    type
                    required
                    customFieldType
                    label
                    bammName
                    leadName
                    options {
                        hasCustomLabel
                        label
                        value
                    }
                }
                contactFormTitle
                contactUsText
                contactUsTitle
                contactUsBammUrl
                contactUsLeadEmailCcList
                contactUsConfirmEmailText
                contactUsConfirmEmailFrom
                id
            }
        }
    }
`;
